export const statusCodeMap = {
    1: "Marcado APP",
    2: "Desmarcado APP",
    4: "Confirmada SMS",
    5: "Desmarcada SMS",
    A: "Recolocada",
    B: "Realizada s/ custos",
    C: "Confirmada",
    D: "Paciente Desistiu",
    E: "Desmarcada",
    F: "Paciente Faltou",
    G: "Paciente N chegou",
    M: "Desmarcada Médico",
    N: "Em Consulta",
    O: "Observação",
    P: "Presença do Paciente",
    R: "Realizada",
    S: "SMS Enviado",
    U: "Urgência",
    V: "Não Atendeu",
    W: "Aguarda Vaga",
    Z: "1.ª Vez",
  };