
export const socketApiUrl = ''
export const apiDomain = ''
export const apiEndpoint = '/api'

export const apiUrl = {
  configrole: apiDomain + apiEndpoint + "/config/role",


  // inbox
  inboxcategories: apiDomain + apiEndpoint + "/messaging/categories",
  messaging: apiDomain + apiEndpoint + "/messaging",
  messagingthread: apiDomain + apiEndpoint + "/messagingthread",
  messagingtag: apiDomain + apiEndpoint + "/messaging/tag",

  // messaging  
  communication: apiDomain + apiEndpoint + "/communication",
  messagingstats: apiDomain + apiEndpoint + "/messaging/stats",
  myactivethreads: apiDomain + apiEndpoint + "/messagingthread/myactiveleads",
  threadsdata: apiDomain + apiEndpoint + "/messagingthread/data",
  relatedcontact: apiDomain + apiEndpoint + "/messagingthread/relatedcontact",

  // phone manager interface
  phonecentralmanager: apiDomain + apiEndpoint + "/phonecentral/manage",

  // users & providers
  users: apiDomain + apiEndpoint + "/users",
  providers: apiDomain + apiEndpoint + "/users/provider",
  operatorgroup: apiDomain + apiEndpoint + "/operator/group",
  operatorgroupMembers: apiDomain + apiEndpoint + "/operator/group/members",
  assignableagents: apiDomain + apiEndpoint + "/operator/agent",
  
  // calls
  createcall: apiDomain + apiEndpoint + "/call/new",
  callDetails: apiDomain + apiEndpoint + "/call/details",

  // exams/analysis
  examsandanalysis: apiDomain + apiEndpoint + "/examsanalysis",
  examsandanalysisResults: apiDomain + apiEndpoint + "/examsanalysis/results",

  // filemanager
  filemanager: apiDomain + apiEndpoint + "/filemanager",

  // ai
  documentinterpret: apiDomain + apiEndpoint + "/ai/documentinterpret",

  //Analytics
  analyticsOptions: apiDomain + apiEndpoint + "/analyticsoptions",

  // Customer Portal
  customerPortalUsers: apiDomain + apiEndpoint + "/customerportal/users",
  customerPortalBookings: apiDomain + apiEndpoint + "/customerportal/bookings",
  customerPortalSync: apiDomain + apiEndpoint + "/customerportal/sync",

  // Integration
  integrationImport: apiDomain + apiEndpoint + "/integration/import",

  // Services
  provservcfg: apiDomain + apiEndpoint + "/provservcfg",
  insurances: apiDomain + apiEndpoint + "/service/insurance",

  // User Context
  userdata: apiDomain + apiEndpoint + "/userdata",
  notification:  apiDomain + apiEndpoint + "/notification",

  // Automation Events
  automationEvents:  apiDomain + apiEndpoint + "/automation/events",
  testAutomationComms:  apiDomain + apiEndpoint + "/automation/test",

}


export const getRequests = {
  appointment: apiDomain + apiEndpoint + "/appointment",
  bi: apiDomain + apiEndpoint + "/bi",
  calendarevents: apiDomain + apiEndpoint + "/calendarevents",
  calendarslots: apiDomain + apiEndpoint + "/calendarslots",
  call: apiDomain + apiEndpoint + "/call",
  calllist: apiDomain + apiEndpoint + "/calllist",
  calladvice: apiDomain + apiEndpoint + "/calladvice",
  callstates: apiDomain + apiEndpoint + "/call/states",
  call_soap: apiDomain + apiEndpoint + "/call/soap",
  center: apiDomain + apiEndpoint + "/center",
  commconfig: apiDomain + apiEndpoint + "/config/comm",
  commdefaultconfig: apiDomain + apiEndpoint + "/config/comm/default",
  communication: apiDomain + apiEndpoint + "/communication",
  customer: apiDomain + apiEndpoint + "/customer",
  customerlist: apiDomain + apiEndpoint + "/customerlist",
  customermanager: apiDomain + apiEndpoint + "/customermanager",
  customerMessage: apiDomain + apiEndpoint + "/customer/message",
  customerpayment: apiDomain + apiEndpoint + "/payment/customer",
  customerweb: apiDomain + apiEndpoint + "/customerweb",
  exams: apiDomain + apiEndpoint + "/examsanalysis",
  fileManager: apiDomain + apiEndpoint + "/filemanager",
  fileMetaData: apiDomain + apiEndpoint + "/filemanager/meta",
  globalconfig: apiDomain + apiEndpoint + "/globalconfig",
  insurance: apiDomain + apiEndpoint + "/insurance",
  landingstats: apiDomain + apiEndpoint + "/stat/landing",
  logcallstat: apiDomain + apiEndpoint + "/logcallstat",
  nextcalls: apiDomain + apiEndpoint + "/nextcalls",
  orgdetails: apiDomain + apiEndpoint + "/config/org",
  payment: apiDomain + apiEndpoint + "/payment",
  prescription: apiDomain + apiEndpoint + "/prescription",
  provider: apiEndpoint + "/provider",
  provservcfg: apiDomain + apiEndpoint + "/provservcfg",
  receptionnote: apiDomain + apiEndpoint + "/call/receptionnote",
  schedule: apiDomain + apiEndpoint + "/schedule",
  servicesubtype: apiDomain + apiEndpoint + "/servicesubtype",
  servicetype: apiDomain + apiEndpoint + "/servicetype",
  spacefeatures: apiDomain + apiEndpoint + "/space-features",
  spaces: apiDomain + apiEndpoint + "/spaces",
  stat: apiDomain + apiEndpoint + "/stat",
  treatmenttype: apiDomain + apiEndpoint + "/treatmenttype",
  userbyuid: apiDomain + apiEndpoint + "/config/userbyuid",
  userconfig: apiDomain + apiEndpoint + "/config/user",
  myuserconfig: apiDomain + apiEndpoint + "/config/myuser",
  usercontext: apiDomain + apiEndpoint + "/context",
  userdata: apiDomain + apiEndpoint + "/userdata",

  // messaging
  messaging: apiDomain + apiEndpoint + "/messaging",
  messagingthread: apiDomain + apiEndpoint + "/messagingthread",

  // Phone central
  phonecentrallist: apiDomain + apiEndpoint + "/phonecentral/list",
  phonecentraldetail: apiDomain + apiEndpoint + "/phonecentral/detail",
  phonecentralmanager: apiDomain + apiEndpoint + "/phonecentral/manage",

  // Permission management
  configrole: apiDomain + apiEndpoint + "/config/role",
  userrole: apiDomain + apiEndpoint + "/config/user/role",
}

export const postRequests = {
  calendarslots: apiDomain + apiEndpoint + "/calendarslots",
  calendarunav: apiDomain + apiEndpoint + "/calendarunav",
  calladvice: apiDomain + apiEndpoint + "/calladvice",
  callbooking: apiDomain + apiEndpoint + "/call",
  callstates: apiDomain + apiEndpoint + "/call/states",
  center: apiDomain + apiEndpoint + "/center",
  commconfig: apiDomain + apiEndpoint + "/config/comm",
  communication: apiDomain + apiEndpoint + "/communication",
  configmedia: apiDomain + apiEndpoint + "/config/media",
  customeractions: apiDomain + apiEndpoint + "/customer/actions",
  customermanager: apiDomain + apiEndpoint + "/customermanager",
  customerMessage: apiDomain + apiEndpoint + "/customer/message",
  customersmartcardupdate: apiDomain + "api/customerupdate/smartcard",
  docupload: apiDomain + apiEndpoint + "/docupload",
  exams: apiDomain + apiEndpoint + "/examsanalysis",
  fileManagerCall: apiDomain + apiEndpoint + "/filemanager/call",
  globalconfig: apiDomain + apiEndpoint + "/globalconfig",
  insurance: apiDomain + apiEndpoint + "/insurance",
  login: apiDomain + apiEndpoint + "/auth/login",
  messaging: apiDomain + apiEndpoint + "/messaging",
  photoupload: apiDomain + apiEndpoint + "/photoupload",
  provider: apiDomain + apiEndpoint + "/provider",
  receptionnote: apiDomain + apiEndpoint + "/call/receptionnote",
  refreshToken: apiDomain + apiEndpoint + "/refresh",
  resendmfa: apiDomain + apiEndpoint + "/auth/resendmfa",
  search: apiDomain + apiEndpoint + "/search",
  sendreminder: apiEndpoint + "/messaging/reminder",
  servicesubtype: apiDomain + apiEndpoint + "/servicesubtype",
  servicetype: apiDomain + apiEndpoint + "/servicetype",
  slot: apiDomain + apiEndpoint + "/slot",
  slotvalidation: apiDomain + apiEndpoint + "/appointment/validate",
  spacefeatures: apiDomain + apiEndpoint + "/space-features",
  spaces: apiDomain + apiEndpoint + "/spaces",
  userrecpwd: apiDomain + apiEndpoint + "/config/user/recover",
  usersendinvite: apiDomain + apiEndpoint + "/config/user/sendinvite",

  // User management
  // Self edit
  myuserrequestemailverification: apiDomain + apiEndpoint + "/config/myuser/requestemailverification",
  myuseremailcodevalidationinput: apiDomain + apiEndpoint + "/config/myuser/emailcodevalidationinput",

  // Other user edit
  userconfig: apiDomain + apiEndpoint + "/config/user",
  emailinvite: apiDomain + apiEndpoint + "/config/user/emailinvite",


  phonecentralmanagerinternal: apiDomain + apiEndpoint + "/phonecentral/manage/internal",

  // Messaging
  //messagingdetailnote: apiDomain + apiEndpoint + "/messaging/detailnote",

  // Permission management
  userrole: apiDomain + apiEndpoint + "/config/user/role",

}

export const deleteRequests = {
  callstates: apiDomain + apiEndpoint + "/call/states",
  calladvice: apiDomain + apiEndpoint + "/calladvice",
  center: apiDomain + apiEndpoint + "/center",
  customermanager: apiDomain + apiEndpoint + "/customermanager",
  exams: apiDomain + apiEndpoint + "/examsanalysis",
  insurance: apiDomain + apiEndpoint + "/insurance",
  servicesubtype: apiDomain + apiEndpoint + "/servicesubtype",
  servicetype: apiDomain + apiEndpoint + "/servicetype",
  slot: apiDomain + apiEndpoint + "/slot",
  spacefeatures: apiDomain + apiEndpoint + "/space-features",
  spaces: apiDomain + apiEndpoint + "/spaces",
  useravatar: apiDomain + apiEndpoint + "/config/user/avatar",
  userconfig: apiDomain + apiEndpoint + "/config/user",

  // Phone central
  phonecentralmanagerinternal: apiDomain + apiEndpoint + "/phonecentral/manage/internal",

}

export const putRequests = {
  call: apiDomain + apiEndpoint + "/call",
  callstates: apiDomain + apiEndpoint + "/call/states",
  calladvice: apiDomain + apiEndpoint + "/calladvice",
  center: apiDomain + apiEndpoint + "/center",
  configrole: apiDomain + apiEndpoint + "/config/role",
  customeredit: apiDomain + apiEndpoint + "/customeredit",
  customermanager: apiDomain + apiEndpoint + "/customermanager",
  exams: apiDomain + apiEndpoint + "/examsanalysis",
  insurance: apiDomain + apiEndpoint + "/insurance",
  orgdetails: apiDomain + apiEndpoint + "/config/org",
  provider: apiDomain + apiEndpoint + "/provider",
  provideredit: apiDomain + apiEndpoint + "/provideredit",
  provservcfg: apiDomain + apiEndpoint + "/provservcfg",
  receptionnote: apiDomain + apiEndpoint + "/call/receptionnote",
  servicesubtype: apiDomain + apiEndpoint + "/servicesubtype",
  servicetype: apiDomain + apiEndpoint + "/servicetype",
  serviceuser: apiDomain + apiEndpoint + "/serviceassignuser",
  spacefeatures: apiDomain + apiEndpoint + "/space-features",
  spaces: apiDomain + apiEndpoint + "/spaces",
  usereditsettings: apiDomain + apiEndpoint + "/config/user/settings",
  userrole: apiDomain + apiEndpoint + "/config/user/role",

  // User management
  // Self edit
  myuserconfig: apiDomain + apiEndpoint + "/config/myuser",
}
