// add custom url arguments

import React, { useState, useEffect } from "react";
import useGetRequest from "../hooks/useGetRequest";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { AutomationEventsTable } from "./AutomationEventsTable";
import TemplateHome from "./templates/TemplateHome";
import TabRouteNavigationControl from "../nav/tabs/TabRouteNavigationControl";
import AutomationWorkflowList from "./workflow/AutomationWorkflowList";
import WorkflowTestForm from "./workflow/WorkflowTestForm";
moment.locale("pt");

export default function AutomationHome() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [sel, setSel] = useState(0);

  const {
    doGet: doGetEventList,
    items: eventList,
    isComplete: isLoadedEventList,
  } = useGetRequest("automationEvents");

  // Set tomorrow's date as default
  useEffect(() => {
    const tomorrow = moment().add(1, "days").toDate(); // Add 1 day to the current date and convert to a JavaScript Date
    setSelectedDate(tomorrow);
  }, []);

  useEffect(() => {
    if (selectedDate)
      doGetEventList({
        date: moment(selectedDate).format(),
      });
  }, [selectedDate]);

  return (
    <div className="p-4 w-full">
      <div className="flex flex-col space-y-3 h-full">
        <TabRouteNavigationControl
          baseRoute={"automation"}
          sel={sel}
          setSel={setSel}
          tabs={[
            {
              title: "Events",
              idx: 0,
              tabCode: "events",
              content: (
                <AutomationEventsTable
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  appointments={isLoadedEventList ? eventList.data : []}
                />
              ),
              disabled: false,
            },
            {
              title: "Workflows",
              idx: 1,
              tabCode: "workflows",
              content: <AutomationWorkflowList />,
              disabled: false,
            },
            {
              title: "Templates",
              idx: 2,
              tabCode: "templates",
              content: <TemplateHome/>,
              disabled: false,
            },
            {
              title: "Test",
              idx: 3,
              tabCode: "test",
              content: <WorkflowTestForm/>,
              disabled: false,
            },
          ]}
        />
      </div>
    </div>
  );
}
