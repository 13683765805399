import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TabRouteNavigationControl = ({ tabs, sel, setSel, baseRoute }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract `tab` query parameter
  const queryParams = new URLSearchParams(location.search);
  const currentTabCode = queryParams.get('tab');

  // Update `sel` based on the `tab` query parameter
  useEffect(() => {
    const currentTabIndex = tabs.findIndex((t) => t.tabCode === currentTabCode);

    if (currentTabIndex !== -1) {
      setSel(currentTabIndex);
    } else {
      // Default to the first tab if no valid `tab` parameter is present
      setSel(0);
      navigate(`/${baseRoute}?tab=${tabs[0].tabCode}`, { replace: true });
    }
  }, [currentTabCode, tabs, setSel, navigate]);

  const handleTabClick = (tabCode, idx) => {
    setSel(idx);
    navigate(`/${baseRoute}?tab=${tabCode}`);
  };

  return (
    <div className="flex flex-col space-y-3 h-full">
      <div>
        <div className="text-sm font-medium text-center text-gray-500 mb-3">
          <ul className="flex flex-wrap -mb-px">
            {tabs.map((t, k) => (
              <li key={k} className="mr-2">
                {!t.disabled ? (
                  <button
                    onClick={() => handleTabClick(t.tabCode, t.idx)}
                    className={
                      t.idx === sel
                        ? 'inline-block p-3 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active'
                        : 'inline-block p-3 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300'
                    }
                  >
                    {t.title}
                  </button>
                ) : (
                  <span className="inline-block p-3 text-gray-400 rounded-t-lg cursor-not-allowed">
                    {t.title}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="h-full" id={sel} role="tabpanel">
        {tabs[sel]?.content}
      </div>
    </div>
  );
};

export default TabRouteNavigationControl;
