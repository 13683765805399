export const colmapping = [
    { key: "firstdate", label: "First Date", width: "130px" },
    { key: "patientname", label: "Patient", width: "150px" },
    { key: "doctorname", label: "Médico", width: "150px" },    
    { key: "patientnumber", label: "Tel. Paciente", width: "90px" },
    { key: "appstatus", label: "Status", width: "100px" },
    { key: "appinfo", label: "Info", width: "250px" },
    {
      key: "res_contacts_send_date",
      label: "NS Data",
      width: "150px",
    },
    {
      key: "res_contacts_message",
      label: "NS SMS",
      width: "200px",
    },
    {
      key: "res_contacts_destination",
      label: "NS Destinatário",
      width: "200px",
    },
    {
      key: "res_contacts_client_response",
      label: "NS Resposta",
      width: "200px",
    },
  ]

