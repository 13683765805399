"use client";

import { useEffect, useState } from "react";
import { workflowComponents } from "./workflowtest";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import usePostRequest from "../../hooks/usePostRequest";

export default function WorkflowTestForm() {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [testResult, setTestResult] = useState(null);
  const {
    doPost: doPostUpdate,
    items: postItems,
    isComplete: postIsComplete,
  } = usePostRequest("testAutomationComms");

  const handleComponentChange = (componentId) => {
    const component = workflowComponents.find((c) => c.id === componentId);
    setSelectedComponent(component || null);
    setInputValues({});
    setTestResult(null);
  };

  const handleInputChange = (name, value) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedComponent) return;

    // Simulate API call
    setTestResult("Testing...");
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Generate dynamic JSON for the selected component
    const dynamicJson = {
      componentType: selectedComponent.id,
      inputs: Object.fromEntries(
        selectedComponent.inputs.map((input) => [
          input.name,
          inputValues[input.name] || "",
        ])
      ),
    };

    setTestResult(JSON.stringify(dynamicJson, null, 2));

    if (selectedComponent.id == "send-sms")
      doPostUpdate({
        channel: "sms",
        contact_to: inputValues.phoneNumber,
        message: inputValues.message,
      });
    if (selectedComponent.id == "ivr-phone-call")
      doPostUpdate({
        channel: "phone-call",
        contact_to: inputValues.phoneNumber
      });
  };

  useEffect(() => {
    if (postIsComplete) setTestResult(JSON.stringify(postItems));
  }, [postIsComplete]);

  return (
    <Card className="w-full max-w-2xl mx-auto border-0 shadow-none bg-slate-50 p-6">
      <CardHeader>
        <CardTitle>Workflow Component Test Form</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="component-select">
                Select Workflow Component
              </Label>
              <Select onValueChange={handleComponentChange}>
                <SelectTrigger id="component-select">
                  <SelectValue placeholder="Select a component" />
                </SelectTrigger>
                <SelectContent>
                  {workflowComponents.map((component) => (
                    <SelectItem key={component.id} value={component.id}>
                      {component.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {selectedComponent && (
              <div className="space-y-4">
                {selectedComponent.inputs.map((input) => (
                  <div key={input.name} className="space-y-2">
                    <Label htmlFor={input.name}>{input.label}</Label>
                    {input.type === "textarea" ? (
                      <Textarea
                        id={input.name}
                        placeholder={input.placeholder}
                        value={inputValues[input.name] || ""}
                        onChange={(e) =>
                          handleInputChange(input.name, e.target.value)
                        }
                      />
                    ) : (
                      <Input
                        type={input.type}
                        id={input.name}
                        placeholder={input.placeholder}
                        value={inputValues[input.name] || ""}
                        onChange={(e) =>
                          handleInputChange(input.name, e.target.value)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={!selectedComponent}
        >
          Test Component
        </Button>
      </CardFooter>
      {testResult && (
        <CardContent>
          <h3 className="text-lg font-semibold mb-2">Test Result:</h3>
          <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto">
            <code>{testResult}</code>
          </pre>
        </CardContent>
      )}
    </Card>
  );
}
