import { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
  ChevronUp,
  ChevronDown,
  CalendarIcon,
  Send,
  Download,
  Search,
} from "lucide-react";
import { statusCodeMap } from "./statuscode";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { AutomationSelectColls } from "./AutomationSelectColls";
import { colmapping } from "./colmap";
moment.locale("pt");

export function AutomationEventsTable({
  appointments,
  selectedDate,
  setSelectedDate,
}) {
  const allColumns = colmapping.map((c) => c.key);

  const [globalFilter, setGlobalFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [showOnlyNSData, setShowOnlyNSData] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFilters, setColumnFilters] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  const [lastSelectedRow, setLastSelectedRow] = useState(null);

  const uniqueStatuses = useMemo(() => {
    return Array.from(new Set(appointments.map((a) => a.appstatus)));
  }, [appointments]);

  const handleSort = (column) => {
    console.log(column);

    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const toggleRowSelection = (appointment, isShiftKey = false) => {
    const key = `${appointment.firstdate}}|${appointment.patientnumber}`;

    if (isShiftKey && lastSelectedRow !== null) {
      const startIndex = filteredAndSortedAppointments.findIndex(
        (row) =>
          `${row.firstdate}|${row.patientname}|${row.patientnumber}` ===
          lastSelectedRow
      );
      const endIndex = filteredAndSortedAppointments.findIndex(
        (row) =>
          `${row.firstdate}|${row.patientname}|${row.patientnumber}` === key
      );

      const [start, end] = [startIndex, endIndex].sort((a, b) => a - b);
      const rowsToSelect = filteredAndSortedAppointments.slice(start, end + 1);

      const keysToSelect = rowsToSelect.map(
        (row) => `${row.firstdate}|${row.patientname}|${row.patientnumber}`
      );

      setSelectedRows((prev) =>
        Array.from(new Set([...prev, ...keysToSelect]))
      );
    } else {
      setSelectedRows((prev) =>
        prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
      );
    }

    setLastSelectedRow(key);
  };

  const updateColumnFilter = (column, value) => {
    setColumnFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  const isRowSelected = (appointment) => {
    const key = `${appointment.firstdate}|${appointment.patientname}|${appointment.patientnumber}`;
    return selectedRows.includes(key);
  };

  const selectAllVisibleRows = () => {
    const visibleKeys = filteredAndSortedAppointments.map(
      (appointment) =>
        `${appointment.firstdate}|${appointment.patientname}|${appointment.patientnumber}`
    );
    setSelectedRows(visibleKeys);
  };

  const pivotedAppointments = useMemo(() => {
    const grouped = appointments.reduce((acc, curr) => {
      const key = `${curr.firstdate}|${curr.patientname}|${curr.patientnumber}`;
      if (!acc[key]) {
        acc[key] = {
          firstdate: curr.firstdate,
          patientname: curr.patientname,
          patientnumber: curr.patientnumber,
          appstatus: [],
          appinfo: [],
          res_contacts_send_date: [],
          res_contacts_message: [],
          res_contacts_destination: [],
          res_contacts_client_response: [],
        };
      }
      acc[key].appstatus.push(curr.appstatus);
      acc[key].appinfo.push(curr.appinfo);
      acc[key].res_contacts_send_date.push(curr.res_contacts_send_date);
      acc[key].res_contacts_message.push(curr.res_contacts_message);
      acc[key].res_contacts_destination.push(curr.res_contacts_destination);
      acc[key].res_contacts_client_response.push(
        curr.res_contacts_client_response
      );
      return acc;
    }, {});

    return Object.values(grouped).map((item) => ({
      ...item,
      appstatus: Array.from(new Set(item.appstatus)).join(", "),
      appinfo: Array.from(new Set(item.appinfo)).join(", "),
      res_contacts_send_date: Array.from(
        new Set(item.res_contacts_send_date)
      ).join(", "),
      res_contacts_message: Array.from(new Set(item.res_contacts_message)).join(
        ", "
      ),
      res_contacts_destination: Array.from(
        new Set(item.res_contacts_destination)
      ).join(", "),
      res_contacts_client_response: Array.from(
        new Set(item.res_contacts_client_response)
      ).join(", "),
    }));
  }, [appointments]);

  const filteredAndSortedAppointments = useMemo(() => {
    return pivotedAppointments
      .filter((appointment) => {
        const globalMatch = Object.values(appointment).some((value) =>
          String(value).toLowerCase().includes(globalFilter.toLowerCase())
        );
        const statusMatch = statusFilter
          ? appointment.appstatus.includes(statusFilter)
          : true;
        const nsDataMatch =
          !showOnlyNSData || appointment.res_contacts_send_date;
        const columnMatch = Object.keys(columnFilters).every((key) =>
          columnFilters[key]
            ? String(appointment[key] || "")
                .toLowerCase()
                .includes(columnFilters[key].toLowerCase())
            : true
        );
        return globalMatch && statusMatch && nsDataMatch && columnMatch;
      })
      .sort((a, b) => {
        if (!sortColumn) return 0;
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (valueA == null || valueB == null) return 0;
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortDirection === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (!isNaN(valueA) && !isNaN(valueB)) {
          return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
        } else {
          return 0;
        }
      });
  }, [
    pivotedAppointments,
    globalFilter,
    statusFilter,
    showOnlyNSData,
    columnFilters,
    sortColumn,
    sortDirection,
  ]);

  const downloadExcel = () => {
    const dataToExport = filteredAndSortedAppointments.map((appointment) => ({
      "First Date": appointment.firstdate,
      Patient: appointment.patientname,
      "Tel Paciente": appointment.patientnumber,
      Status: appointment.appstatus,
      Info: appointment.appinfo,
      "NS Data": appointment.res_contacts_send_date,
      "NS SMS": appointment.res_contacts_message,
      "NS Destinatário": appointment.res_contacts_destination,
      "NS Resposta": appointment.res_contacts_client_response,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

    XLSX.writeFile(workbook, "appointments_pivoted.xlsx");
  };

  const totalRows = filteredAndSortedAppointments.length;
  const totalSelectedRows = selectedRows.length;

  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  function StringToList({ commaSeparatedString }) {
    // Split the string by commas and trim whitespace
    if (commaSeparatedString == null || !commaSeparatedString) return "";
    const items = commaSeparatedString.split(",").map((item) => item.trim());

    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row gap-4">
        <div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={
                  "w-[280px] justify-start text-left font-normal " +
                    !selectedDate && "text-muted-foreground"
                }
              >
                <CalendarIcon className="size-3 mr-3" />
                {selectedDate ? (
                  format(selectedDate, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={selectedDate}
                onSelect={setSelectedDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <Input
          placeholder="Global filter..."
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="max-w-sm"
        />
        <Select
          value={statusFilter || "all"}
          onValueChange={(value) =>
            setStatusFilter(value === "all" ? null : value)
          }
        >
          <SelectTrigger className="max-w-[200px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            {uniqueStatuses.map((status) => (
              <SelectItem key={status} value={status}>
                {status}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <AutomationSelectColls
          allColumns={allColumns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
        <Button
          onClick={() => setShowOnlyNSData((prev) => !prev)}
          variant={!showOnlyNSData ? "primary" : "secondary"}
        >
          <Send className="size-4" />
        </Button>
        <Button onClick={downloadExcel} variant="primary">
          <Download className="size-4" />
        </Button>
      </div>
      <div className="text-sm">
        Total Rows: {totalRows}, Selected Rows: {totalSelectedRows}
      </div>
      <div className="space-y-2"></div>
      <div className="rounded-md border">
        <Table className="text-[11px]">
          <TableHeader>
            <TableRow className="bg-slate-100">
              <TableHead style={{ width: "50px" }}>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    e.target.checked
                      ? selectAllVisibleRows()
                      : setSelectedRows([])
                  }
                />
              </TableHead>
              {colmapping
                .filter((column) => visibleColumns.includes(column.key))
                .map((column) => {
                  const nonEmptyCount = filteredAndSortedAppointments.filter(
                    (a) => a[column.key] && a[column.key].trim()
                  ).length;
                  return (
                    <TableHead key={column.key} style={{ width: column.width }}>
                      <div className="flex flex-col items-left space-y-1 py-2">
                        <Button
                          onClick={() => handleSort(column.key)}
                          className="justify-start text-xs bg-inherit border-0 shadow-none p-0 m-0 text-inherit hover:bg-inherit text-left"
                        >
                          {column.label}
                          {sortColumn === column.key &&
                            (sortDirection === "asc" ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            ))}
                        </Button>
                        <Input
                          placeholder={`Filter ${column.label}`}
                          value={columnFilters[column.key] || ""}
                          onChange={(e) =>
                            updateColumnFilter(column.key, e.target.value)
                          }
                          className="max-w-full text-[9px] h-6 py-0.5 px-1"
                        />
                        <span className="text-[9px]">{nonEmptyCount} rows</span>
                      </div>
                    </TableHead>
                  );
                })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredAndSortedAppointments.map((appointment, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor: isRowSelected(appointment)
                    ? "#e0f7fa"
                    : "transparent",
                }}
              >
                <TableCell>
                  <input
                    type="checkbox"
                    checked={isRowSelected(appointment)}
                    onChange={(e) =>
                      toggleRowSelection(appointment, e.shiftKey)
                    }
                  />
                </TableCell>
                {visibleColumns.includes("firstdate") && (
                  <TableCell>
                    {moment(appointment.firstdate).format("H:mm DD/MM/YYYY")}
                  </TableCell>
                )}
                {visibleColumns.includes("patientname") && (
                  <TableCell>
                    {capitalizeFirstLetter(appointment.patientname)}
                  </TableCell>
                )}
                 {visibleColumns.includes("doctorname") && (
                  <TableCell>
                    {capitalizeFirstLetter(appointment.doctorname)}
                  </TableCell>
                )}
                {visibleColumns.includes("patientnumber") && (
                  <TableCell>
                    <StringToList
                      commaSeparatedString={appointment.patientnumber}
                    />
                  </TableCell>
                )}
                {visibleColumns.includes("appstatus") && (
                  <TableCell>
                    <StringToList
                      commaSeparatedString={
                        statusCodeMap[appointment.appstatus]
                      }
                    />
                  </TableCell>
                )}
                {visibleColumns.includes("appinfo") && (
                  <TableCell className="text-[9px]">
                    <StringToList commaSeparatedString={appointment.appinfo} />
                  </TableCell>
                )}
                {visibleColumns.includes("res_contacts_send_date") && (
                  <TableCell>{appointment.res_contacts_send_date}</TableCell>
                )}
                {visibleColumns.includes("res_contacts_message") && (
                  <TableCell className="text-[9px]">
                    {appointment.res_contacts_message}
                  </TableCell>
                )}
                {visibleColumns.includes("res_contacts_destination") && (
                  <TableCell>{appointment.res_contacts_destination}</TableCell>
                )}
                {visibleColumns.includes("res_contacts_client_response") && (
                  <TableCell>
                    {appointment.res_contacts_client_response}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
