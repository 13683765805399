import * as React from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { colmapping } from "./colmap";
import { Columns3 } from "lucide-react";

export function AutomationSelectColls({ allColumns, visibleColumns, setVisibleColumns }) {

function colLabel(key){
    const col = colmapping.find(c => c.key == key)
    if(!col) return null
    return col.label
}

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="primary"><Columns3 className="h-4 w-4"/></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Colunas</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {allColumns.map((c) => (
          <DropdownMenuCheckboxItem
            key={c}
            checked={visibleColumns.includes(c)}
            onCheckedChange={() => {
              if (visibleColumns.includes(c)) setVisibleColumns(visibleColumns.filter(i => i != c));
              else setVisibleColumns([...visibleColumns, c]);
            }}
          >
            {colLabel(c)}
          </DropdownMenuCheckboxItem>
        ))}

        
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
