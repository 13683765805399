
  
  export const workflowComponents = [
    {
      id: 'send-sms',
      name: 'Send SMS',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'message', type: 'textarea', label: 'Message', placeholder: 'Enter your SMS message' },
      ],
    },
    {
      id: 'webhook-sms',
      name: 'Webhook SMS',
      inputs: [
        { name: 'webhookUrl', type: 'url', label: 'Webhook URL', placeholder: 'https://example.com/webhook' },
        { name: 'payload', type: 'textarea', label: 'Payload', placeholder: '{"message": "Hello, world!"}' },
      ],
    },
    {
      id: 'send-email',
      name: 'Send Email',
      inputs: [
        { name: 'to', type: 'email', label: 'To', placeholder: 'recipient@example.com' },
        { name: 'subject', type: 'text', label: 'Subject', placeholder: 'Email Subject' },
        { name: 'body', type: 'textarea', label: 'Body', placeholder: 'Enter your email content' },
      ],
    },
    {
      id: 'webhook-email',
      name: 'Webhook Email',
      inputs: [
        { name: 'webhookUrl', type: 'url', label: 'Webhook URL', placeholder: 'https://example.com/webhook' },
        { name: 'payload', type: 'textarea', label: 'Payload', placeholder: '{"to": "recipient@example.com", "subject": "Test", "body": "Hello"}' },
      ],
    },
    {
      id: 'simple-phone-call',
      name: 'Make Simple Phone Call',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'message', type: 'textarea', label: 'Message to Read', placeholder: 'Enter the message to be read during the call' },
      ],
    },
    {
      id: 'ivr-phone-call',
      name: 'Make Phone Call with IVR',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'welcomeMessage', type: 'textarea', label: 'Welcome Message', placeholder: 'Welcome to our IVR system' },
        { name: 'options', type: 'textarea', label: 'IVR Options', placeholder: 'Enter options as JSON: [{"digit": "1", "message": "Press 1 for sales"}, ...]' },
      ],
    },
  ];
  
  